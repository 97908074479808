var exports = {};
!function (e, n) {
  exports = n();
}(exports, function () {
  "use strict";

  return function (e, n, t) {
    t.updateLocale = function (e, n) {
      var o = t.Ls[e];
      if (o) return (n ? Object.keys(n) : []).forEach(function (e) {
        o[e] = n[e];
      }), o;
    };
  };
});
export default exports;